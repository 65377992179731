import React, { useEffect, useState } from "react";
import "./navbar.css";
import { Stack, UserCircle, Info } from "phosphor-react";
import mobileLogo from "../../assets/logo20p.png";
import pcLogo from "../../assets/logo50p.png";
import { useLocation, useNavigate } from "react-router-dom";

export const Navbar = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [scrolled, setScrolled] = useState(false);
    const [previousPath, setPreviousPath] = useState(null); // Store the previous path
    const location = useLocation();
    const navigate = useNavigate();

    const menuClicked = () => {
        if (location.pathname === "/menu") {
            // Navigate back to the previous path if it exists
            navigate(previousPath || "/");
        } else {
            // Store the current path before navigating to the menu
            setPreviousPath(location.pathname);
            navigate("/menu");
        }
    };

    const goHome = () => {
        navigate("/");
    };
    const gocContact = () => {
        navigate("/contact");
    };
    const goEnquire = () => {
    
        navigate("/enquire");
    };

    const showMiddleLogo = location.pathname !== "/";

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > (window.innerHeight / 1.8) || showMiddleLogo) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [showMiddleLogo]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        const handleScroll = () => {
            if (window.scrollY > (window.innerHeight / 1.8)) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleScroll);

        handleResize();
        handleScroll();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`Navbar ${scrolled ? "scrolled" : ""}`}>
            <div className="top">
                <div className="menuPallate" onClick={menuClicked}>
                    <Stack size={32} color="white" />
                </div>
            </div>
            <div className="middle">
                <img 
                    src={isMobile ? mobileLogo : pcLogo} 
                    alt="Logo" 
                    className="logo" 
                    onClick={goHome}
                />
            </div>
            <div className="bottom" style={{ color: 'white' }}>
                <div className="ocontact">
                    <UserCircle size={32} color="white" onClick={gocContact} />
                    <a>Contact</a>
                </div>
                <div className="oservices">
                    <Info size={32} color="white"  onClick={goEnquire}/>
                    <a>Info</a>
                </div>
            </div>
        </div>
    );
};
