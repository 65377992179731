import React, { useEffect, useState } from "react";
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import "./newHomepage.css";
import logoSm from "../../assets/logo50p.png";
import logoBg from "../../assets/logo100.png";
import brokenScreen from "../../assets/scrackedPhone.webp";
import batteryRep from "../../assets/batteryrepair.webp";
import computerRepair from "../../assets/cracked_laptop_side.avif";
import othersPics from "../../assets/maxresdefault.jpg";
import { useNavigate } from "react-router-dom";  // <-- Correct import for useNavigate

export const Homepage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const navigate = useNavigate();  // <-- Initialize the useNavigate hook

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    console.log(isMobile);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handlePhoneClick = () => {
        const phoneNumber = "08068692511";
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleEmailClick = () => {
        const email = "vivregop@gmail.com";
        const subject = 'Subject Here';
        const body = 'Body of the email here';
        window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, "messages"), formData);
            alert("Message sent successfully!");

            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("Failed to send message.");
        }
    };

    return (
        <div className="homepage">
            <header className="header">
                <div className="contact-info">
                    <p className="phoneNo" onClick={handlePhoneClick}>Phone:080 (6869) 2511</p>
                    <p className="email" onClick={handleEmailClick}>vivregop@gmail.com</p>
                </div>
            </header>

            <section className="hero">
                <img src={isMobile ? logoSm : logoBg}
                    alt="logo"
                    className="titleLogo"
                ></img>
                <a className="cta-button2" href="https://www.facebook.com/profile.php?id=61563144225614">Facebook page</a>
            </section>

            <div className="space0">
                <div className="space0Cont">
                Welcome to your go-to spot for quick fixes and happy phones! 
                Whether it’s a cracked screen, a battery that’s given up, or a computer that’s thrown in the towel, we’ve got the magic touch. At our repair shop, we don’t just repair; we revive, refresh, and restore your favorite gadgets to their former glory. Fast, friendly, and always reliable—because your tech deserves nothing less!
                </div>
            </div>

            <div className="space00">
                <a href="#contact" className="cta-button">Enquire Here</a>
            </div>
        
            <section className="servicesI">
                <h2>Our Services</h2>
                <div className="serviceItemsI">
                    <div 
                        className="service-itemI" 
                        style={{ 
                            backgroundImage: `url(${brokenScreen})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            color: "white" 
                        }}
                        onClick={() => navigate('/screenrepair')} // <-- Correct usage of navigate
                    >
                        <h2>Screen Replacement</h2>
                        <p>Quick and affordable screen repairs for all brands.</p>
                    </div>

                    <div 
                        className="service-itemI"
                        style={{ 
                            backgroundImage: `url(${batteryRep})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            color: "white" 
                        }}
                        onClick={() => navigate('/batteryrepair')}  // <-- Correct usage of navigate
                    >
                        <h2>Battery Replacement</h2>
                        <p>Get a new battery for your device and extend its life.</p>
                    </div>

                    <div 
                        className="service-itemI"
                        style={{ 
                            backgroundImage: `url(${computerRepair})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            color: "white" 
                        }}
                        onClick={() => navigate('/computerrepair')}  // <-- Correct usage of navigate
                    >
                        <h2>Computer Repair</h2>
                        <p>Expert repair services for damaged computers.</p>
                    </div>

                    <div 
                        className="service-itemI"
                        style={{ 
                            backgroundImage: `url(${othersPics})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            color: "white" 
                        }}
                        onClick={() => navigate("/otherservices")}  // <-- Correct usage of navigate
                    >
                        <h2>Other Services</h2>
                        <p>We also take personal requests</p>
                    </div>
                </div>
            </section>

            <section className="contact" id="contact">
                <h2>Contact Us</h2>
                <p>Address: Chiba-shi Chuo-ku Innai 2- 1- 1</p>
                <p onClick={handleEmailClick}>Email: vivregop@gmail.com</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <button type="submit">Send Message</button>
                </form>
            </section>

            <footer className="footer">
                <p>&copy; 2024 RepairShop. All rights reserved.</p>
                <div className="social-media">
                    <a href="https://www.facebook.com/profile.php?id=61563144225614">Facebook</a>
                </div>
            </footer>
        </div>
    );
};
