import { Phone,Envelope } from 'phosphor-react';
import React from 'react';
import './contact.css';
export const Contact = () => {
    const handlePhoneClick = () => {
        // Logic to navigate to the phone page
        window.location.href = 'tel:+818068692511';
    };

    const handleEmailClick = () => {
        // Logic to navigate to the email page
        window.location.href = 'mailto:vivregop@gmail.com';
    };

    return (
        <div className='contactPage'>
            <h1>Contact Info</h1>
            <div className="call">
            <div className="phone" onClick={handlePhoneClick}><Phone size={32} /></div>
            <a>Call us : +81 08068692511</a>
            </div>
            <div className="mailp2">
                <div className="emailp2" onClick={handleEmailClick}><Envelope size={32}/></div>
                <a>Email: vivregop@gmail.com</a>
            </div>
            <div className="facebook">
            <a className="cta-button2" href="https://www.facebook.com/profile.php?id=61563144225614">Text us on facebook</a>
            </div>
        </div>
    );
};

