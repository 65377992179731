import React, { useState } from 'react';
import { db } from '../../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import "./enquire.css";

export const Enquire = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, "messages"), formData);
            alert("Message sent successfully!");

            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("Failed to send message.");
        }
    };


    return (
       <div className="contactPage">


        <section className="contact" id="contact">
            <h2>Send us a message</h2>
            <p>Address: Chiba-shi Chuo-ku Innai 2-1-1</p>
            <p>Email: vivregop@gmail.com</p>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="message">Message:</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>
                <button type="submit">Send Message</button>
            </form>
        </section>
       </div>
    );
};

