import React from 'react';
import { Repair } from './repair';
const OtherServices = () => {
  

    return (
        <div className='Bigcontainer'>
        <div className='content-container'>
            {Repair.map((repair, key) => {
                return (
                    <div key={key} className='repair-card'>
                        <h2>{repair.name}</h2>
                        <img className='productImage' src={repair.productImage} alt='Screen Repair' />
                        <p>{repair.description}</p>
                      
                    </div>
                );
            })}
            </div>
    </div>
    );
};

export default OtherServices;