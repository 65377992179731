import React, { useState } from "react";
import { serviceItems } from "../../../jsfiles/menu";
import "./menu.css";
import { useNavigate } from "react-router-dom";

export const Menu = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const navigate = useNavigate();
  return (
    <>
      <button onClick={toggleMenu}>Menu</button>
      <div className={`MenuPalette ${isActive ? "active" : ""}`}>
        <div className="serviceItems">
          {serviceItems.map((item) => (
            <div
              key={item.id}
              className="service-item"
              style={{
                backgroundImage: `url(${item.backgroundImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                color: "white"
              }}
              onClick={() => navigate(item.location)}
            >
              <h2>{item.title}</h2>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};