
import { Navbar } from './components/navbar/navbar';
import { BrowserRouter as Router, Routes, Route, redirect } from "react-router-dom";

import './App.css';
import { Homepage } from './pages/homescreen/homepage';
import { Contact } from './components/navbar/contact/contact';
import { Enquire } from './components/navbar/enquire/enquire';
import { Menu } from './pages/menu/menuI/menu';




import ScreenRepair from './pages/servicePage/screenrepair/screenrepair';
import ComputerRepair from './pages/servicePage/computerrepair/computerRepair';
import OtherServices from './pages/servicePage/otherservices/otherServices';
import BatteryRepair from './pages/servicePage/batteryrepair/batteryRepair';

function App() {
  return (    <Router>
      <Navbar/>
      <Routes>
            <Route path="/screenrepair" element={<ScreenRepair />} />
            <Route path="/computerrepair" element={<ComputerRepair />} />
            <Route path="/otherservices" element={<OtherServices />} />
            <Route path="/batteryrepair" element={<BatteryRepair />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/menu" element={<Menu />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/enquire" element={<Enquire />}/>
      </Routes>
    </Router>
  );
}

export default App;
/*
<Route path="/" element={<Homepage />} />
*/