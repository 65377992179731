// src/components/menu/serviceItems.js
import brokenScreen from "../assets/scrackedPhone.webp";
import batteryRep from "../assets/batteryrepair.webp";
import computerRepair from "../assets/cracked_laptop_side.avif";
import othersPics from "../assets/maxresdefault.jpg";

export const serviceItems = [
    {
        id: 1,
        title: "Screen Replacement",
        description: "Quick and affordable screen repairs for all brands.",
        backgroundImage: brokenScreen,
        location: "/screenrepair"
    },
    {
        id: 2,
        title: "Battery Replacement",
        description: "Get a new battery for your device and extend its life.",
        backgroundImage: batteryRep,
        location: "/batteryrepair"
    },
    {
        id: 3,
        title: "Computer Repair",
        description: "Expert repair services for damaged computers.",
        backgroundImage: computerRepair,
        location: "/computerrepair" 
    },
    {
        id: 4,
        title: "Custom Requests",
        description: "Recovering data from damaged computers.",
        backgroundImage: othersPics,
        location: "/otherservices"
    }
];
