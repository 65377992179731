// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuEJ_zLODEAZvQ9J_cb5L99y4Z8Blhi3A",
  authDomain: "repairshop-5dff9.firebaseapp.com",
  projectId: "repairshop-5dff9",
  storageBucket: "repairshop-5dff9.appspot.com",
  messagingSenderId: "229327180912",
  appId: "1:229327180912:web:a89e52e40e89a363c790b5",
  measurementId: "G-WWCTP8N6ZE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };