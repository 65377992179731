import React from 'react';
import { Repair } from './repair';
const ComputerRepair = () => {
    // Add your code here

    return (
        <div className='Bigcontainer'>
            <div className='content-container'>
                {Repair.map((repair, key) => {
                    return (
                        <div key={key} className='repair-card'>
                            <h2>{repair.name}</h2>
                            <img className='productImage' src={repair.productImage} alt='Screen Repair' />
                            <p>{repair.description}</p>
                            <a>Devices:</a>
                            <h2>{repair.devices}</h2>
                        </div>
                    );
                })}
                </div>
        </div>
    );
};

export default ComputerRepair;